/** @format */

import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";

const HomeCard = lazy(() => import("./Home/index"));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));

const Router = () => {

  return (
    <Switch>
      <Route exact path="/">
        <HomeCard />
      </Route>
      <Route exact path="/privacy-policy">
        <PrivacyPolicy />
      </Route>
      <Route exact path="/terms-and-conditions">
        <TermsAndConditions />
      </Route>
      <Route exact path="/cookie-policy">
        <CookiePolicy />
      </Route>
    </Switch>
  );
};

export default Router;
